// Sidebar styles

.sidebar-container {
  position: relative;
  height: 100%;
  background-color: rgb(27 35 48);
}

.sidebar {
  z-index: 1;
  flex-shrink: 0;
  width: 120px;
  height: 100%;
  box-shadow: 8px 0 15px -3px rgb(0 0 0 / 10%);
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.sidebar.hidden {
  margin-left: -120px;
}

.sidebar__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  max-height: 100px;

  .sidebar-logo {
    display: flex;
    justify-content: center;
    padding: 0 16px;
    width: 100%;
    pointer-events: none;

    img {
      width: 42px;
    }
  }

  .sidebar-toggle {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff;
  }
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 100px);

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 72px;
    padding: 8px;
    color: rgb(255 255 255 / 70%);

    .sidebar-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 4px;
      margin-bottom: 6px;
      border-radius: 12px;
      padding: 0;
      width: 100%;
      height: 100px;
      min-height: 100px;
      color: rgb(255 255 255 / 70%);
      cursor: pointer;

      * {
        flex-grow: 0;
        margin: 0;
      }

      &:hover {
        background-color: rgb(103 103 103 / 22.4%);
      }
    }

    .sidebar__content-title {
      list-style: none;
      margin: 24px 0 4px;
      padding: 8px 16px;
      text-transform: uppercase;
      color: rgb(255 255 255 / 70%);
      cursor: default;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 48px;
        letter-spacing: 0.025em;
      }
    }

    div {
      div:nth-child(1) {
        margin-bottom: 8px;
        min-width: 0;
        color: inherit;
      }

      div:nth-child(2) {
        span {
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
    }
  }
}

// Sidebar mobile

.sidebar-mobile {
  position: absolute;
  z-index: 100;
  flex-shrink: 0;
  width: 120px;
  height: 100%;
  box-shadow: 8px 0 15px -3px rgb(0 0 0 / 10%);
  background-color: rgb(37 47 62);
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  .sidebar__content {
    ul {
      .sidebar-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 4px;
        margin-bottom: 6px;
        border-radius: 6px;
        padding: 0;
        width: 100%;
        height: 100px;
        min-height: 100px;
        color: rgb(255 255 255 / 70%);
        cursor: pointer;
      }
    }
  }
}

.sidebar-mobile.hidden {
  margin-left: -280px;
}

.sidebar_open {
  display: block;
}

.sidebar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgb(0 0 0 / 50%);
}
