.chat.hidden {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  width: 70px;
  height: 100vh;
  min-width: 70px;
  max-width: 70px;
}

.chat.hidden > .chat__inner {
  position: absolute;
  z-index: 100;
  margin: 0;
  width: 360px;
  height: 100vh;
  min-width: 360px;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 6px -1px, rgb(0 0 0 / 6%) 0 2px 4px -1px;
  background-color: rgb(255 255 255);
  transform: translate3d(0, 0, 0);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  inset: 0;
}

.chat {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  width: 70px;
  height: 100vh;
  min-width: 70px;
  max-width: 70px;

  .chat__inner {
    .chat__header {
      flex-shrink: 0;
      height: 64px;

      .chat-head {
        display: flex;
        justify-content: space-between;
        padding: 0 4px;
        height: 100%;
        color: rgb(255 255 255);
        background-image: none;
        background-color: rgb(37 47 62);

        .chat-button {
          appearance: none;
          position: relative;
          display: inline-flex;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          overflow: visible;
          margin: 0;
          outline: 0;
          border: 0;
          border-radius: 50%;
          padding: 12px;
          box-sizing: border-box;
          font-size: 2.6rem;
          text-align: center;
          text-decoration: none;
          color: inherit;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          cursor: pointer;
          user-select: none;

          svg {
            width: 32px;
            height: 32px;
          }
        }

        .chat-button__close {
          appearance: none;
          position: relative;
          display: inline-flex;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          overflow: visible;
          margin: 0;
          outline: 0;
          border: 0;
          border-radius: 50%;
          padding: 12px;
          box-sizing: border-box;
          font-size: 2.6rem;
          text-align: center;
          text-decoration: none;
          color: inherit;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          cursor: pointer;
          user-select: none;

          span:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.chat > .chat__inner {
  position: absolute;
  z-index: 100;
  margin: 0;
  width: 360px;
  height: 100vh;
  min-width: 360px;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 6px -1px, rgb(0 0 0 / 6%) 0 2px 4px -1px;
  background-color: rgb(255 255 255);
  transform: translate3d(-290px, 0, 0);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  inset: 0;
}

.chat-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  opacity: 0;
}

.app-chat__inner {
  position: relative;
}

@media (max-width: 1200px) {
  .chat.hidden {
    width: 0;
    min-width: 0;
    max-width: 0;
  }

  .chat.hidden > .chat__inner {
    position: fixed;
    left: auto;
    width: 320px;
    min-width: 320px;
    max-width: 100%;
    box-shadow: none;
    transform: translate3d(360px, 0, 0);
  }

  .chat {
    width: 0;
    min-width: 0;
    max-width: 0;
  }

  .chat > .chat__inner {
    position: fixed;
    left: auto;
    width: 320px;
    min-width: 320px;
    max-width: 100%;
    box-shadow: rgb(0 0 0 / 10%) 0 10px 15px -3px, rgb(0 0 0 / 5%) 0 4px 6px -2px;
    transform: translate3d(0, 0, 0);
  }
}
