// Header

.header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  box-shadow: 6px 1px 15px 0 rgb(0 0 0 / 25%);
  background-color: #fff;

  &-services {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 100%;
  }

  &-features {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-right: 8px;
    padding-left: 8px;
    height: 100%;

    button {
      flex-grow: 1;
      border-radius: 24px;
      padding: 6px 8px;
      min-width: fit-content;
    }

    .button-toggle-chat {
      display: none;
      color: rgb(0 0 0 / 54%);
    }

    .button-switch-language {
      p {
        margin: 0 4px;
        font-weight: 700;
        font-size: 0.8rem;
        line-height: 1.5;
        text-transform: uppercase;
        color: rgb(107 114 128);
      }

      img {
        margin: 0 4px;
        width: 16px;
      }

      span {
        color: rgb(37 47 62);
      }
    }

    .button-profile {
      border-radius: 18px;
      padding: 6px 16px;
      min-height: 40px;

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        span,
        p {
          margin: 0;
          text-transform: capitalize;
        }

        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 1.5;
          color: rgb(107 114 128);
        }

        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 1.5;
        }
      }

      &__info,
      &__avatar {
        margin: 0 4px;
        pointer-events: none;
      }
    }
  }
}

.sidebar-toggle {
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;

  & svg {
    width: 16px;
    height: 16px;
    color: rgb(17 24 39);
  }
}

// Hide sidebar

.header__sidebar_hidden {
  display: none;
}

// Header mobile

@media (max-width: 1200px) {
  .header {
    height: 48px;

    &-features {
      button {
        display: flex;
        flex-grow: 1;
        border-radius: 24px;
        height: 40px;
        min-width: fit-content;
      }

      .button-profile {
        margin-right: 8px;
        margin-left: 8px;
        padding: 0;
        width: 40px;
        min-width: 40px;

        &__info {
          display: none;
        }

        &__avatar {
          margin: 0;
          width: 100%;
          height: 100%;
        }

        &__info,
        &__avatar {
          pointer-events: none;
        }
      }

      .button-toggle-chat {
        display: flex;
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 1px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 20px;
      box-shadow: rgb(0 0 0 / 24%) 0 0 0 20px inset;
    }
  }

  // Switcher mobile customize

  .py-9 {
    padding: 6px 0;

    .languages-list {
      min-height: 36px;

      img {
        width: 16px;
      }

      span {
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1;
      }
    }
  }

  // Sidebar Toggle mobile

  .sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 40px;
    height: 40px;
    background-color: transparent;
    cursor: pointer;

    & svg {
      width: 16px;
      height: 16px;
      color: rgb(17 24 39);
    }
  }
}

// Switcher customize

.py-9 {
  padding: 6px 0;

  .languages-list {
    img {
      width: 16px;
    }

    span {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }
}
