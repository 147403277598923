.error-wrapper {
  height: 100%;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  height: 100%;

  a {
    font-size: 14px;
    text-decoration: underline;
    color: #22d3ee;
  }
}

.error__inner {
  max-width: 36.2rem;
  text-align: center;

  .error__normal-text {
    margin-bottom: 1.6rem;
    font-weight: 500;
  }

  .error__small-text {
    margin-bottom: 1.6rem;
  }

  .error__paper {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1.2rem;
    border-radius: 16px;
    padding: 1rem;

    .error__input {
      padding: 0 1.6rem;
      font-size: 14px;
    }
  }
}
