.agents-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  .agents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;

    .agent-buttons-inner {
      .agent-button-group {
        display: flex;
        height: 100%;

        .agent-buttons {
          width: fit-content;
          min-width: auto;

          div {
            min-width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .agents-wrapper {
    .agents {
      td {
        padding: 12px;
        max-height: 128px;
        line-height: normal;
        text-align: center;
      }
    }
  }
}
