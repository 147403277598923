.modal__inner {
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 400px;
  height: 160px;
  box-shadow: 8px 0 15px -3px rgb(0 0 0 / 10%);
  color: #000;
  background-color: #fff;
}

.modal__inner_ip {
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 600px;
  height: 280px;
  box-shadow: 8px 0 15px -3px rgb(0 0 0 / 10%);
  color: #000;
  background-color: #fff;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 900px) {
  .modal__inner {
    width: 260px;
    height: 160px;
  }

  .modal__inner_ip {
    width: 340px;
    height: 280px;
  }
}
