.footer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  height: 64px;
  color: #fff;
  background-color: rgb(30 33 37);
}
